import { Organization } from "./Organization";
import OrganizationContactInfo from "./OrganizationContactInfo";

/**
 * Represents an issuer of a document.
 */
export default class Issuer {
  readonly name: string;
  readonly website?: string;
  readonly phone?: string;
  readonly email?: string;
  readonly logo?: {
    uri: string;
  };
  readonly identityNumber?: string;

  static fromOrganization(organization: Organization): Issuer {
    if (!organization) {
      return new Issuer();
    }
    
    return new Issuer({
      email: organization.email,
      logo: organization.logo,
      name: organization.name,
      phone: organization.phone,
      website: organization.website,
      identityNumber: organization.identityNumber
    });
  }

  constructor(deriveFrom?: Partial<Issuer>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}