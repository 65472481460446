import React from 'react';
import './styles.scss';
import { TooltipTrigger } from '../Tooltip/TooltipTrigger';
import FontAwesomeIcon from '../fa_icon';
import { TooltipPosition } from '../Tooltip/Tooltip';

interface Props {
  children: any;
  position?: TooltipPosition;
}

/**
 * Hoverable small help icon.
 * @author Johan Svensson
 */
export default function HelpIcon(props: Props) {
  return (
    <TooltipTrigger
      className="help-icon"
      position={props.position}
      tooltip={props.children}>
      <FontAwesomeIcon
        name="question-circle"
        type="regular"
        interactive />
    </TooltipTrigger>
  )
}