import moment from "moment";
import cls from "classnames";
import React, { useState } from "react";
import { map } from "../../../utils";
import styles from "./DateSelect.module.scss";

type Props = {
  year: number;
  /**
   * 0 to 11, where January is 0.
   */
  month: number;

  /**
   * Alias for `start`.
   */
  value?: string | Date;

  start?: string | Date;
  end?: string | Date;

  /**
   * `end` is set when `isRange` is true.
   */
  onChange: (start: Date | null, end?: Date | null) => void;
  isClearable?: boolean;
  isRange?: boolean;
}

/**
 * Lists selectable dates in a grid, starting with monday.
 */
export const DateList: React.FC<Props> = ({
  month,
  year,
  value,
  start,
  end,
  onChange,
  isClearable = false,
  isRange = false,
}) => {
  start = start || value;

  const [hoveredDate, setHoveredDate] = useState<Date>(null);

  const startAsMoment = start ? moment(start) : null;
  const endAsMoment = end ? moment(end) : null;

  const viewedDate = moment().year(year).month(month).startOf('day');

  let startDate = moment(viewedDate).startOf('month');
  while (startDate.isoWeekday() !== 1) {
    startDate = startDate.subtract(1, 'day');
  }

  let endDate = moment(viewedDate).endOf('month');
  while (endDate.isoWeekday() !== 7) {
    endDate = endDate.add(1, 'day');
  }

  const hoveredDateIsBeforeStart = hoveredDate && hoveredDate < startAsMoment?.toDate();
  const hoveredDateIsAfterStart = hoveredDate && hoveredDate > startAsMoment?.toDate();

  return (
    <div
      className={styles.listContainer}
      onMouseLeave={() => setHoveredDate(null)}>
      <header className={styles.weekDayList}>
        {
          map(7, offset => (
            <div key={offset} className={styles.weekDayListItem}>
              {moment().weekday(offset).format('dd')}
            </div>
          ))
        }
      </header>
      <div role="list" className={styles.dateList}>
        {
          map(Math.ceil(endDate.diff(startDate, 'days') + 1), offset => {
            const date = moment(startDate).add(offset, 'days');
            const isToday = date.format('L') === moment().format('L');
            const isSelectedAsStart = startAsMoment && startAsMoment.format('L') === date.format('L');
            const isSelectedAsEnd = endAsMoment && endAsMoment.format('L') === date.format('L');
            const isSelected = isSelectedAsStart || isSelectedAsEnd;

            let isIntermediate = isRange && date.isBefore(endAsMoment) && date.isAfter(startAsMoment);
            if (hoveredDateIsBeforeStart) {
              isIntermediate = isRange && date.isBefore(endAsMoment || startAsMoment) &&
                date.isAfter(hoveredDate);

            } else if (hoveredDateIsAfterStart) {
              isIntermediate = isRange && date.isBefore(hoveredDate) && date.isAfter(startAsMoment);

            }

            return (
              <div
                key={date.toISOString()}
                className={cls([
                  styles.dateItem,
                  date.month() !== month ? styles.otherMonth : null,
                  isToday ? styles.today : null,
                  isSelected ? styles.selected : null,
                  isIntermediate ? styles.intermediate : null,
                ])}
                role="button"
                onMouseEnter={() => setHoveredDate(date.toDate())}
                onMouseLeave={() => setHoveredDate(previousDate => date.isSame(previousDate) ? null : previousDate)}
                onClickCapture={() => {
                  const clearStart = isSelectedAsStart && isClearable;
                  const clearEnd = isSelectedAsEnd && isClearable;
                  const setStart = start && isRange && date.isBefore(startAsMoment);
                  const setEnd = start && isRange && date.isAfter(startAsMoment);
                  const setStartKeepTime = Boolean(start);

                  if (clearStart && clearEnd) {
                    //  When start = end, and we click the element to clear.
                    onChange(null, null);
                  } else if (clearStart && endAsMoment) {
                    //  End date should become start date.
                    //  There shouldn't be an end date without a start date.
                    onChange(endAsMoment?.toDate() ?? null, null);

                  } else if (clearStart) {
                    onChange(null, endAsMoment?.toDate() ?? null);

                  } else if (clearEnd) {
                    onChange(startAsMoment?.toDate(), null);

                  } else if (setStart) {
                    //  Clicking on a date before start, with end date = move start date, keep end date.
                    onChange(date.toDate(), (endAsMoment || startAsMoment).toDate());

                  } else if (setEnd) {
                    //  Click on date after start = set end date.
                    onChange(startAsMoment.toDate(), date.toDate());

                  } else if (setStartKeepTime) {
                    onChange(moment(date).hour(startAsMoment.hour()).minute(startAsMoment.minute()).toDate());

                  } else {
                    onChange(date.toDate());

                  }
                }}>
                {date.date()}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
