import { Country } from "../models/Country";
import { I18n } from "sweet-i18n";

let i18n: I18n = null!;
let lastSchema: string = '';

/**
 * Updates text translations.
 * @param schema Document with translations of phrases.
 */
export function setI18nSchema(schema: string) {
  lastSchema = schema;
  i18n = new I18n('se', schema, 'se');
}

setI18nSchema(''); // Temporary until a new schema is loaded.

i18n.language = localStorage.getItem('language') || 'se';

/**
   * Translates a phrase. If a matching translation is missing, then the original phrase is returned.
 */
export function text(phraseOrKey: string | TemplateStringsArray, ...args: any[]) {
  return i18n.get(phraseOrKey, ...args);
}

/**
 * Returns the current language of translated texts.
 */
export function getLanguage(): Country {
  return i18n.language as Country;
}

/**
 * Sets the language of translated texts.
 */
export function setLanguage(language: Country) {
  i18n.language = language;
}

export function getSchema() {
  return lastSchema;
}

export function getI18n() {
  return i18n;
}