import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import cls from 'classnames';
import HelpIcon from '../help_icon';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '../fa_icon';
import TaskIndicator from '../task_indicator';
import styles from './Dropdown.module.scss';
import { isEscapeKey } from '../../../utils';
import { insertEventListener, removeIndexedEventListener } from '../../../utils/events';
import FileHandler from '../file_handler';
import escapeStringRegexp from 'escape-string-regexp';
import { createTemporaryId } from '../../../utils/model';
import { boolean } from "boolean";
import { Button } from '../Button/Button';
import { isEventFromCurrentTarget } from '../../../utils/dom';

type Interaction = void | boolean;

export type DropdownItem = {
  /**
   * Recommended to set this to a unique value.
   */
  key?: string | number;
  text: ReactNode;
  checked?: boolean;
  checkboxBefore?: boolean;
  description?: ReactNode | null;
  weight?: 'danger' | 'normal' | 'link' | 'dimmed';
  taskIndicator?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  externalLinkTo?: string;
  linkTo?: string;
  object?: any;
  testId?: string;
  autoScrollTo?: boolean;

  /**
   * Makes the dropdown item a label root with a file input.
   */
  fileAccept?: string;
  onFileOpen?: (file: File) => void;
  onClick?: (event: React.MouseEvent) => Interaction;

  /**
   * Whether onClick should be called when disabled.
   * @default false
   */
  onClickWhenDisabled?: boolean;
  queryHelpers?: string[];
};

export type DropdownGroup = {
  key: string;
  title: string;
  items: DropdownItem[];

  /**
   * If true, then this will be hidden initially.
   * The user can expand hidden items or groups to reveal them.
   */
  isHidden?: boolean;
};

export interface DropdownProps {
  title?: string;
  items?: (DropdownItem | null | undefined)[];
  groups?: DropdownGroup[];
  open?: boolean;
  horizontalPosition?: 'left' | 'right';
  verticalPosition?: 'top' | 'bottom';
  onClose: () => void;
  onItemClicked?: (item: DropdownItem, group: DropdownGroup, groupIndex: number) => void;

  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;

  className?: string;

  /**
   * Rendered in case no items are rendered.
   */
  placeholder?: string;

  /**
   * Rendered in case the user's query yields no results.
   */
  queryPlaceholder?: string;

  /**
   * Placeholder for query input.
   */
  queryInputPlaceholder?: string;

  /**
   * Set to share some state with other dropdowns.
   */
  singularId?: string;

  /**
   * Used to minimize visual elements. If `true`, then
   * 
   * * Query field will be removed if there are few listed items in total.
   * * If there is 1 group and 1 only, then the group's items are listed without group header.
   */
  autoCompact?: boolean;

  /**
   * Hides the search bar if there are few items.
   */
  autoCompactQuery?: boolean;

  /**
   * Whether to sort items so that disabled items appear last.
   * @default false
   */
  sortEnabledFirst?: boolean;
}

let dropdowns: { id: string; close: () => void; }[] = [];

/**
 * Closes other dropdowns. The dropdown which just opened, is not closed.
 */
function closeOtherDropdowns(openId: string) {
  for (const dropdown of dropdowns) {
    if (dropdown.id !== openId) {
      try {
        dropdown.close();
      } catch (error) { }
    }
  }
}

const root = document.getElementById('dropdowns');

const isExpandedBySingularId: { [singularId: string]: true } = {};

/**
 * Dropdown which will be dismissed if clicked outside of it. Expects a relatively positioned parent container.
 * 
 * Once open, the dropdown will close upon a click anywhere outside of the dropdown element.
 * To prevent closing a dropdown upon click, set a `data-keep-dropdown-open` attribute on that element,
 * then clicking it will not cause dropdowns to close. The dropdown itself has this attribute.
 */
export const Dropdown: React.FC<DropdownProps> = ({
  items,
  onClose,
  horizontalPosition = 'left',
  verticalPosition = 'bottom',
  className,
  onItemClicked,
  onMouseEnter,
  onMouseLeave,
  open,
  placeholder,
  queryInputPlaceholder,
  queryPlaceholder,
  title,
  groups,
  singularId,
  autoCompact,
  autoCompactQuery,
  sortEnabledFirst = false,
}) => {
  if ((items || title) && groups) {
    throw new Error("Cannot provide both items and groups");
  }

  if (!(items || title) && !groups) {
    throw new Error("Provide either items or groups");
  }

  autoCompactQuery = autoCompactQuery ?? autoCompact;

  if (!groups) {
    groups = [{ items: items || [], title, key: Math.random().toString(24) }];
  }

  groups = groups.map(group => {
    //  Remove false-ish items.
    const items = group.items.filter(item => item);

    if (sortEnabledFirst) {
      items.sort((a, b) => (a.disabled ? 1 : 0) - (b.disabled ? 1 : 0));
    }

    return { ...group, items };
  });

  const [query, setQuery] = useState('');
  const [visible, setVisible] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const dropdownElement = useRef<HTMLElement>(null);
  const anchorElement = useRef<HTMLElement>(null);
  const autoScrollTargetElement = useRef<HTMLElement>(null);
  const autoPositionDropdownRef = useRef<any>(null);
  const id = useRef(createTemporaryId());
  const isOpenRef = useRef(open);
  isOpenRef.current = open;

  //  `groups` before `open` was changed to false.
  //  Having this state prevents changes to the dropdown at the same time as closing.
  const listedGroups = useRef<DropdownGroup[]>([]);

  if (open) {
    listedGroups.current = groups;
  }

  useEffect(() => {
    dropdowns.push({ close: () => onClose(), id: id.current });

    return () => {
      const index = dropdowns.findIndex(dropdown => dropdown.id === id.current);
      if (index !== -1) {
        dropdowns.splice(index, 1);
      }
    }
  }, []);

  useEffect(() => {
    if (open) {
      closeOtherDropdowns(id.current);
    }

    if (open && singularId) {
      setExpanded(isExpandedBySingularId[singularId] ?? false);
    }
  }, [open]);

  function autoScroll(scrollable = dropdownElement.current, target = autoScrollTargetElement.current) {
    if (!scrollable || !target || didAutoScroll.current || !open) {
      return;
    }

    const parentTop = target.parentElement.getBoundingClientRect().top;
    const top = target.getBoundingClientRect().top + scrollable.scrollTop - parentTop;

    scrollable.scrollTo({ top });
    didAutoScroll.current = true;
  }

  function positionDropdown(anchor = anchorElement.current, dropdown = dropdownElement.current) {
    if (!anchor || !dropdown || !isOpenRef.current) {
      return;
    }

    if (!isOpenRef.current) {
      //  Having the position change immediately after selecting an item looks bad.
      return;
    }

    const maxY = window.innerHeight - 16;
    const rootBounds = root.getBoundingClientRect();

    const anchorBounds = anchor.getBoundingClientRect();
    const dropdownBounds = dropdown.getBoundingClientRect();
    let top = anchorBounds.top;

    if (verticalPosition === "top") {
      top -= dropdownBounds.height + anchorBounds.height;
    }

    let bottom = top + dropdownBounds.height;

    if (bottom > maxY) {
      top -= bottom - maxY;
    }

    dropdown.style.width = Math.max(anchorBounds.width, dropdownBounds.width) + 'px';
    dropdown.style.top = top + 'px';

    if (horizontalPosition === 'right') {
      dropdown.style.left = 'unset';
      dropdown.style.right = ((rootBounds.left + rootBounds.width) - anchorBounds.right) + 'px';
    } else {
      dropdown.style.right = 'unset';
      dropdown.style.left = (anchorBounds.left - rootBounds.left) + 'px';
    }
  }

  function autoPositionDropdown() {
    autoPositionDropdownRef.current = setInterval(() => {
      if (!dropdownElement.current) {
        return;
      }

      dropdownElement.current.classList.add(styles.transition);

      positionDropdown();
    }, 1000);
  }

  useEffect(() => {
    if (dropdownElement.current) {
      //  Resize as children changes.
      dropdownElement.current.style.width = null;
      positionDropdown();
    }
  }, [listedGroups.current]);

  const didAutoScroll = useRef(false);

  useEffect(() => {
    if (!open) {
      didAutoScroll.current = false;
      clearInterval(autoPositionDropdownRef.current);
      if (dropdownElement.current) {
        dropdownElement.current.classList.remove(styles.transition);
      }
    } else {
      setVisible(true);
      autoScroll();
      autoPositionDropdown();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const closeOnClickListener = (event: MouseEvent) => {
        if (event.target === document.body) {
          //  Workaround for an unidentified bug where dropdowns are closed immediately.
          //  There is at least one element covering the body anyway.
          return true;
        }

        const elementPath = event.composedPath() as HTMLElement[];
        if (elementPath.find(element => boolean(element.getAttribute?.('data-keep-dropdown-open')))) {
          return true;
        }

        event.stopPropagation()
        event.preventDefault()

        onClose?.();
      };

      const closeOnEscapeListener = (event: KeyboardEvent) => {
        if (isEscapeKey(event as any)) {
          event.stopPropagation()
          event.preventDefault()

          onClose?.();
          return;
        }
      };

      const closableTimeoutId = setTimeout(() => {
        insertEventListener('click', closeOnClickListener, 0);
        insertEventListener('keyup', closeOnEscapeListener, 0);
      }, 500); // Workaround for dropdowns immediately closing upon opening. Example: https://trello.com/c/5iIfe1Cu

      return () => {
        clearTimeout(closableTimeoutId);
        removeIndexedEventListener('click', closeOnClickListener);
        removeIndexedEventListener('keyup', closeOnEscapeListener);
      }
    }
  }, [open]);

  useEffect(() => {
    if (!visible) {
      setQuery('');
    }
  }, [visible]);

  if (!visible) {
    return null;
  }

  const isQueryable = Boolean(listedGroups.current.find(group => group.items.find(item => item.queryHelpers?.length)))
    && (autoCompactQuery ? listedGroups.current.reduce((count, group) => count + group.items.length, 0) >= 5 : true);

  const queryRegexp = query ? new RegExp(escapeStringRegexp(query.trim()), 'i') : null;
  const queriedItemsByGroupId: { [groupKey: string]: DropdownItem[]; } = listedGroups.current.reduce((p, group) => ({
    ...p,
    [group.key]: queryRegexp ? group.items.filter(item => item.queryHelpers?.find(part => queryRegexp.test(part))) : group.items
  }), {});

  let queriedGroups = query ? listedGroups.current.filter(group => queriedItemsByGroupId[group.key].length) : listedGroups.current;
  if (!isExpanded && !queriedGroups.find(group => !group.isHidden)) {
    //  If the user queried for a hidden item then automatically expand (but not permanently).
    queriedGroups = queriedGroups.map(group => ({ ...group, isHidden: false }));
  }

  queriedGroups.sort((a, b) => a.isHidden ? 1 : 0 - (b.isHidden ? 1 : 0)); // Position hidden last. 

  const isQueryNoResult = queriedGroups.length === 0 && listedGroups.current.length > 0;

  let anchorStyles: CSSProperties = {};

  if (verticalPosition === "top") {
    anchorStyles.position = 'absolute';
    anchorStyles.right = 0;
    anchorStyles.top = 0;
  }

  return (
    <div
      ref={e => {
        anchorElement.current = e;
        positionDropdown(e);
      }}
      style={anchorStyles}>
      {createPortal((
        <>
          <div
            ref={e => {
              dropdownElement.current = e;
              autoScroll(e);
              positionDropdown(anchorElement.current, e);
            }}
            className={cls([styles.dropdown, open ? styles.open : null, className])}
            data-vertical-position={verticalPosition}
            data-horizontal-position={horizontalPosition}
            data-keep-popover-open
            data-keep-dropdown-open
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onAnimationEnd={event => {
              if (isEventFromCurrentTarget(event) && !open) {
                setVisible(false)
              }
            }}>

            {
              isQueryable ? (
                <header className={styles.queryContainer}>
                  <DropdownQueryInput
                    onChange={setQuery}
                    value={query}
                    placeholder={queryInputPlaceholder} />
                </header>
              ) : null
            }

            {
              queriedGroups.length ? (
                queriedGroups.map((group, groupIndex) => {
                  if (group.isHidden && !isExpanded) {
                    return null;
                  }

                  const items = queriedItemsByGroupId[group.key];

                  if (!items.length) {
                    return null;
                  }

                  return (
                    <>
                      {
                        group.title && (autoCompact ? listedGroups.current.length > 1 : true) ? (
                          <label className={styles.title}>{group.title}</label>
                        ) : null
                      }
                      {
                        items.map((item, i) => (
                          <DropdownItemComponent
                            key={item.key ? String(item.key) : item.object || String(item.text)}
                            _ref={item.autoScrollTo ? e => {
                              autoScrollTargetElement.current = e;
                              autoScroll(dropdownElement.current, e);
                            } : null}
                            item={item}
                            index={i}
                            onClick={event => {
                              onItemClicked?.(item, group, groupIndex);
                              if (item.onClick?.(event) === true) {
                                onClose?.();
                              }
                            }} />
                        ))
                      }
                    </>
                  );
                })
              ) : placeholder || isQueryNoResult ? (
                <p className={styles.placeholder}>
                  {isQueryNoResult ? queryPlaceholder || "Inga matchningar" : placeholder}
                </p>
              ) : null
            }

            {
              !isExpanded && queriedGroups.find(group => group.isHidden) ? (
                <div style={{ width: '100%', padding: '0 8px', marginTop: 6, boxSizing: 'border-box' }}>
                  <Button
                    style={{ width: '100%' }}
                    size="compact"
                    icon={"chevron-down"}
                    onClick={event => {
                      event.stopPropagation();

                      if (singularId) {
                        isExpandedBySingularId[singularId] = true;
                      }

                      setExpanded(true);
                    }}>
                    Visa mer
                  </Button>
                </div>
              ) : null
            }
          </div>
        </>
      ), root)}
    </div>
  )
}

const DropdownQueryInput: React.FC<{ value: string; onChange: (value: string) => void; placeholder?: string; }> = ({ onChange, value, placeholder }) => {
  const [isFocused, setFocused] = useState(false);

  return (
    <div
      className={cls([styles.query, isFocused ? styles.focused : null])}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}>
      <input
        ref={e => {
          if (e) {
            e.focus({
              preventScroll: true
            });
          }
        }}
        placeholder={placeholder || 'Sök'}
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
        spellCheck={false} />
    </div>
  )
}

function DropdownItemComponent(props: {
  _ref?: (e: HTMLElement) => void,
  item: DropdownItem,
  index: number,
  onClick?: React.MouseEventHandler,
}) {
  const { item, index } = props;

  const children = (
    <>
      <div style={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}>
        <div className={styles.iconContainer}>
          {
            item.taskIndicator ? (
              <TaskIndicator className={cls([styles.custom, styles.taskIndicator])} type="tiny" animated={!item.icon} />
            ) : typeof item.checked === 'boolean' && item.checkboxBefore ? (
              <DropdownlItemCheckbox item={item} />
            ) : item.icon && typeof item.icon === 'string' ? (
              <FontAwesomeIcon className={styles.icon} name={item.icon} />
            ) : item.icon ? (
              <div className={styles.icon}>
                {item.icon}
              </div>
            ) : null
          }
        </div>
        {item.text || 'alla'}

        {
          item.description && typeof item.checked === 'boolean' ? (
            <HelpIcon>{item.description}</HelpIcon>
          ) : null
        }
      </div>
      {
        //  If rendering a checkbox, then render the help icon next to `text`.
        typeof item.checked === 'boolean' && !item.checkboxBefore ? (
          <DropdownlItemCheckbox item={item} />
        ) : item.description ? (
          <HelpIcon>{item.description}</HelpIcon>
        ) : null
      }
    </>
  );

  let weight = item.weight || 'normal';

  const componentProps: any = {
    key: index.toString(),
    className: cls([styles.item, styles[weight], item.disabled ? styles.disabled : null]),
    "data-value": item.text,
    to: item.linkTo,
    onClick: event => {
      if (props.item.disabled && !props.item.onClickWhenDisabled) {
        return;
      }

      if (props.item.taskIndicator) {
        return;
      }

      props.onClick?.(event);
    },
    children,
    "data-id": item.testId,
    href: item.externalLinkTo
  };

  if (componentProps.href) {
    componentProps.target = "_blank";
  }

  if (item.fileAccept) {
    return (
      <label ref={props._ref} {...componentProps}>
        {componentProps.children}
        <FileHandler accept={item.fileAccept} onFilesOpen={(files) => item.onFileOpen?.(files[0])} />
      </label>
    )
  }

  if (item.linkTo) {
    return (
      <Link innerRef={props._ref} to={item.linkTo} {...componentProps} />
    )
  }

  return (
    <a ref={props._ref} {...componentProps} />
  );
}

/**
 * Checkbox for a dropdown item.
 */
const DropdownlItemCheckbox: React.FC<{ item: DropdownItem }> = ({ item }) => {
  return (
    <input type="checkbox"
      className={styles.checkbox} checked={item.checked} />
  )
}