import React from 'react';
import cls from 'classnames';
import { Button } from '../Button/Button';
import styles from './error_screen_wrapper.module.scss';
import ErrorInfo from './ErrorInfo';
import SupportContact from './SupportContact';
import { SadBoi } from './SadBoi';
import { globalUserStore } from '../../user/UserDispatcher';
import { AppType, appType } from '../../../AppType';

class State {
  lastError?: Error;
}

function reload() {
  window.location.reload();
}

/**
 * Displays a generic error message if an exception was caught on this component's level.
 * @author Johan Svensson
 */
export default class ErrorScreenWrapper extends React.Component<any, State> {
  state = new State();

  reportedErrors: Error[] = [];

  componentDidCatch(error: Error) {
    if (error?.name === "ChunkLoadError") {
      //  Error loading a JS chunk, happens on release. Clicking the reload button get rid of the error.
      reload();
      return;
    }

    this.setState({ lastError: error });
    this.sendUserLog(error);
  }

  sendUserLog(error: Error) {
    if (this.reportedErrors.find(sentError => sentError.name == error.name) != null) {
      console.debug("Already reported error", error);
      return false;
    }

    const currentUser = globalUserStore.state.users.current;

    console.error('alert: Crash', JSON.stringify({
      location: document.location.href,
      user: {
        type: currentUser?.type
      },
      ...error,
      name: error.name,
      stack: error.stack,
      message: error.message
    }));

    this.reportedErrors.push(error);
  }

  render() {
    const { lastError } = this.state;
    const { children } = this.props;

    if (lastError) {
      return (
        <div className={cls([styles.screen, "fade-in"])}>
          <h1>
            <SadBoi />
            <span>Något gick snett</span>
          </h1>

          <div>
            <Button
              onClick={() => reload()}
              weight="primary"
              icon="redo-alt">
              Ladda om sidan
            </Button>
          </div>

          {
            appType !== AppType.OfferDocument ? (
              <SupportContact />
            ) : null
          }
          <ErrorInfo error={lastError} />
        </div>
      )
    }

    return children;
  }
}