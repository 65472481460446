import { ValidationResult } from ".";
import { OfferTemplate, OfferTemplateComponent, OfferTemplateComponentType, OfferTemplateMultiComponentGroup, OfferTemplateType } from "../models/OfferTemplate";

function hasMultiTabs(template: OfferTemplate): boolean | null {
  const component = template.getComponentByType(OfferTemplateComponentType.Multi);
  if (!component) {
    return null;
  }

  const args = component.arguments.multi;
  return args?.groups.length > 0;
}

function findMultiComponentGroupWithoutText(template: OfferTemplate): OfferTemplateMultiComponentGroup | null {
  const component = template.getComponentByType(OfferTemplateComponentType.Multi);
  if (!component) {
    return null;
  }

  const args = component.arguments.multi;
  return args?.groups?.find(({ text }) => !text?.trim());
}

function findRecoveredCoverImage(template: OfferTemplate): any | null {
  return template.cover?.recovered && !template.cover.file ? template.cover : null;
}

function findMultiComponentGroupWithoutName(template: OfferTemplate): OfferTemplateMultiComponentGroup | null {
  const component = template.getComponentByType(OfferTemplateComponentType.Multi);
  if (!component) {
    return null;
  }

  const args = component.arguments.multi;
  return args?.groups?.find(({ name }) => !name?.trim());
}

function findTextComponentWithoutText(template: OfferTemplate): OfferTemplateComponent | null {
  const component = template.getComponentByType(OfferTemplateComponentType.Text);
  if (!component) {
    return null;
  }

  const args = component.arguments?.text;
  if (!args?.text) {
    return component;
  }

  return null;
}

/**
 * Validates an offer template.
 * @param template Offer template to validate.
 * @returns A list of localized errors, or `true`
 */
export function validateOfferTemplate(template: OfferTemplate): ValidationResult {
  var group: OfferTemplateMultiComponentGroup;

  let errors: string[] = [];

  if (template.type !== OfferTemplateType.ExternalDocument && !template.components?.length) {
    errors.push('Mallen är tom. Lägg till ett block först');
  }

  if (hasMultiTabs(template) === false) {
    errors.push('Ange minst en flik');
  }

  group = findMultiComponentGroupWithoutText(template);
  if (group != null) {
    errors.push(`Ange text i fliken ${group.name || 'Namnlös flik'}`);
  }

  if (findTextComponentWithoutText(template) != null) {
    errors.push('Ett eller flera textblock saknar text');
  }

  if (findMultiComponentGroupWithoutName(template)) {
    errors.push('En eller flera flikar saknar namn');
  }

  if (findRecoveredCoverImage(template)) {
    errors.push('Välj en omslagsbild igen');
  }

  return errors.length ? errors : true;
}