import React, { CSSProperties } from 'react'
import cls from 'classnames';
import './styles.scss';
import FontAwesomeIcon from '../fa_icon';

interface Props {
  children: any;
  absolute?: boolean;
  className?: string;
  animated?: boolean;
  size?: 'normal' | 'small';
  style?: CSSProperties;
}

/**
 * A brief label which is centered in a relative/absolute parent.
 * @author Johan Svensson
 */
export default function Placeholder({ children, absolute, animated = true, className, style, size = 'normal' }: Props) {
  return (
    <p
      className={cls([cls({
        "table-placeholder": true,
        "absolute": absolute,
        "animated": animated
      }), className])}
      style={style}
      data-size={size}>
      {children}
    </p>
  )
}

export function PlaceholderIcon(props: { name: string, type: 'regular' | 'solid' }) {
  return (
    <FontAwesomeIcon {...props} className="icon-large" />
  )
}