import easyScroll from 'easy-scroll';
import { detect as detectBrowser } from 'detect-browser';

const browser = detectBrowser();

/**
 * Arbitrary animation with callback.
 */
export function animate(opts: {
  duration: number;
  easing: (x: number) => number;
  callback: (fraction: number, elapsedTime: number) => void;
}) {
  let initialTimestamp: number = null;

  const frame = (timestamp: number) => {
    if (initialTimestamp == null) {
      initialTimestamp = timestamp;
    }

    const elapsedTime = timestamp - initialTimestamp
    const fraction = opts.easing(elapsedTime / opts.duration);
    opts.callback(fraction, elapsedTime);

    if (elapsedTime < opts.duration) {
      requestAnimationFrame(frame);
    }
  }

  requestAnimationFrame(frame);
}

export const easings = {
  linear: (x: number) => x
}

export function windowDistanceToBottom() {
  return document.body.offsetHeight - (window.innerHeight + window.scrollY);
}

/**
 * Smooth scrolls the window or document body by a given amount.
 */
export function smoothScrollBy(y: number, el: Window | HTMLElement | 'auto' = window) {
  const { body } = document;
  return smoothScrollTo((window.scrollY || body.scrollTop) + y, el);
}

/**
 * Smooth scrolls the window or document body to a given amount.
 */
export function smoothScrollTo(y: number, el: Window | HTMLElement | 'auto' = window) {
  const { body } = document;

  if (el === 'auto') {
    el = browser.os == 'iOS' ? window : body;
  }

  easyScroll({
    scrollableDomEle: el,
    direction: 'bottom',
    scrollAmount: y - (window.scrollY || body.scrollTop),
    duration: 850,
    easingPreset: 'easeInOutCubic'
  });
}

/**
 * Smooth scrolls the window to the bottom.
 */
export function smoothScrollToTop() {
  easyScroll({
    scrollableDomEle: window,
    direction: 'top',
    duration: 850,
    easingPreset: 'easeOutQuad'
  });
}

/**
 * Smooth scrolls the window to the bottom.
 */
export function smoothScrollToBottom() {
  easyScroll({
    scrollableDomEle: window,
    direction: 'bottom',
    duration: 850,
    easingPreset: 'easeOutQuad'
  });
}

let lastPointerPosition: { x: number, y: number } = { x: null, y: null };
window.addEventListener('mousemove', ev => lastPointerPosition = { x: ev.clientX, y: ev.clientY }, { capture: true });

/**
 * @returns The last screen position of the pointer.
 */
export function getLastPointerPosition() {
  return lastPointerPosition;
}

/**
 * Extracts data-* attributes from `props`.
 */
export function getDataAttributes(props: any): { [key: string]: string } {
  if (!props || typeof props !== 'object') {
    return {};
  }

  const data: any = {};

  for (let key in props) {
    if (/^data-/i.test(key)) {
      data[key] = props[key];
    }
  }

  return data;
}

export function downloadBlob(blob: Blob, download?: string) {
  const anchor = document.createElement('a');
  anchor.download = download;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.click();
  window.URL.revokeObjectURL(anchor.href);
}

/**
 * For lists with an "explode" animation, this method sets
 * the transform original and delay for an item.
 */
export function setExplodeAnimationStyleAttributes(element: HTMLElement) {
  if (!element) {
    return element;
  }

  const { left, width, top, height } = element.getBoundingClientRect();
  const distanceToWindowCenter = [(window.innerWidth / 2) - (left + width / 2), (window.innerHeight / 2) - (top + height / 2)];
  element.style.transformOrigin = `${distanceToWindowCenter[0]}px ${distanceToWindowCenter[1]}px`;
  element.style.animationDelay = (Math.abs(distanceToWindowCenter[0] + distanceToWindowCenter[1]) / 5) + 'ms'

  return element;
}

export function isElementOverflowing(element: HTMLElement): boolean {
  if (!element) {
    return false;
  }
  
  return element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight;
}

/**
 * Returns whether the event was dispatched from the same element which has the listener.
 * 
 * For example, onAnimationEnd can be invoked when an animation from a child element finishes.
 * Use this to only check animations on the element which you have set the listener to.
 */
export function isEventFromCurrentTarget(event: Event | React.SyntheticEvent) {
  return event.currentTarget === event.target;
}