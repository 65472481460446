import { createTemporaryId } from "../utils/model";
import { Country } from "./Country";
import { EnabledFeature, Feature } from "./Feature";
import UploadedFile from "./UploadedFile";
import { UsernameStyle } from "./User";
import UserPreferences from "./UserPreferences";

const usernameStyles = Object.values(UsernameStyle);

/**
 * Represents the current user's organization.
 */
export class Organization {
  readonly id: string;
  /**
   * Changes after each organization update.
   */
  readonly key: string;
  readonly name: string;
  readonly identityNumber: string;
  readonly phone?: string;
  readonly website?: string;
  readonly logo?: UploadedFile;
  readonly email?: string;
  readonly smsSenderId?: string;
  /**
   * Use hasFeature to get enabled features.
   */
  readonly features: EnabledFeature[] = [];
  readonly createdAt: Date;
  readonly usernameStyle: UsernameStyle;
  /**
   * List of countries in which the organization has business in.
   */
  readonly countries: Country[];
  readonly mainCountry: Country = Country.Sweden;
  readonly defaultUserPreferences: UserPreferences = new UserPreferences();

  constructor(deriveFrom?: Partial<Organization>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (!this.countries) {
      this.countries = [Country.Sweden];
    }

    if (!this.mainCountry || !this.countries.includes(this.mainCountry)) {
      this.mainCountry = this.countries[0];
    }

    this.key = createTemporaryId();

    if ((deriveFrom as any)._id && !deriveFrom.id) {
      this.id = (deriveFrom as any)._id;
    }

    if (this.features) {
      this.features = this.features.map(feature => new EnabledFeature(feature));
    }

    this.createdAt = new Date(this.createdAt);

    const usernameStyleIndex = this.id.split('').reduce((index, character) =>
      index + character.charCodeAt(0), 0);

    this.usernameStyle = usernameStyles[usernameStyleIndex % usernameStyles.length];

    if (this.defaultUserPreferences) {
      this.defaultUserPreferences = new UserPreferences(this.defaultUserPreferences);
    }
  }

  hasContactInfo() {
    return Boolean(this.phone || this.email || this.website);
  }

  private _hasFeature(feature: Feature) {
    return Boolean(this.features?.find(({ feature: otherFeature }) => otherFeature === feature));
  }

  hasFeature(feature: Feature) {
    if (feature === Feature.Contact && this._hasFeature(Feature.Dial)) {
      return true;
    }

    return this._hasFeature(feature);
  }

  /**
   * Returns a new, identical instance with the given feature.
   */
  withFeature(feature: Feature): Organization {
    return new Organization({ ...this, features: [...this.features, { since: new Date(), feature }] });
  }

  /**
   * Returns a new, identical instance without the given feature.
   */
  withoutFeature(feature: Feature): Organization {
    return new Organization({
      ...this,
      features: this.features.filter(({ feature: otherFeature }) => otherFeature !== feature),
    });
  }
}