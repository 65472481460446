import React from 'react';
import cls from "classnames";
import { isSupportLogin } from '../../../utils/user';
import styles from './EnvironmentIndicator.module.scss';

/**
 * Renders small indicators for support login and the current environment (alpha, dev).
 */
export const EnvironmentIndicators: React.FC = () => {
  const env = currentEnvironment();
  const _isSupportLogin = isSupportLogin();

  if (env === 'prod' && !_isSupportLogin) {
    return null;
  }

  return (
    <div className={styles.container}>
      {
        _isSupportLogin ? (
          <div className={cls([styles.indicator, styles.support])}>
            SALESYS&nbsp;&nbsp;KUNDSERVICE
          </div>
        ) : null
      }
      {
        env !== "prod" ? (
          <div className={cls([styles.indicator, styles[env]])}>
            SALESYS&nbsp;&nbsp;{env.toUpperCase()}
          </div>
        ) : null
      }
    </div>
  )
}

export function currentEnvironment(): 'dev' | 'next' | 'alpha' | 'prod' {
  const { hostname, port } = document.location;
  if (hostname == 'localhost' || hostname.endsWith('.local')) {
    return 'dev';
  }

  if (hostname.endsWith('elipt.is') && !port?.startsWith('3')) {
    return hostname.includes('salesys-next') ? 'next' : 'alpha';
  }

  if (hostname.endsWith('ngrok-free.app')) {
    return 'dev';
  }

  return 'prod';
}