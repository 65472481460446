import React from "react";
import cls from "classnames";
import { Button, ButtonWeight } from "../Button/Button";
import styles from "./Popover.module.scss";
import { TooltipTrigger } from "../Tooltip/TooltipTrigger";

export type PopoverHeadAsideButton = {
  key: string;
  iconName: string;
  /**
   * Defaults to "dimmed".
   */
  weight?: ButtonWeight;
  onClick: () => void;
  disabled?: boolean;
  description: string;
};

type Props = {
  title?: React.ReactNode;
  asideButtons?: PopoverHeadAsideButton[];
  className?: string;
  interactable?: boolean;
};

/**
 * A popover head which consists of a title and an optional aside button.
 */
export const PopoverHead: React.FC<Props> = ({ asideButtons, title, className, interactable }) => {
  return (
    <PopoverHeadRoot interactable={interactable} className={className}>
      <h3 className={styles.title}>{title}</h3>
      {
        asideButtons.length ? (
          <div className={styles.asideList}>
            {
              asideButtons.map(button => (
                <TooltipTrigger delay={false} tooltip={button.description}>
                  <Button
                    key={button.key}
                    className={styles.aside}
                    icon={button.iconName}
                    weight={button.weight || "dimmed"}
                    disabled={button.disabled}
                    onClick={event => {
                      event.preventDefault();
                      button.onClick();
                    }} />
                </TooltipTrigger>
              ))
            }
          </div>
        ) : null
      }
    </PopoverHeadRoot>
  )
}

const PopoverHeadRoot: React.FC<Pick<Props, 'interactable' | 'className'>> = ({ interactable, className, children }) => {
  if (interactable) {
    return (
      <label className={cls([styles.head, styles.interactable, className])}>
        {children}
      </label>
    )
  }

  return (
    <header className={cls([styles.head, className])}>
      {children}
    </header>
  )
}

/**
 * Returns a default aside button for clearing the value.
 */
export function createClearAsideButton(onClear: () => void, details?: Partial<PopoverHeadAsideButton>): PopoverHeadAsideButton {
  return {
    key: "clear",
    iconName: "trash",
    onClick() {
      onClear();
    },
    description: "Rensa",
    ...details,
  };

}

/**
 * Returns a default aside button for closing the popover.
 */
export function createCloseAsideButton(onClose: () => void, details?: Partial<PopoverHeadAsideButton>): PopoverHeadAsideButton {
  return {
    key: "delete",
    iconName: "times",
    onClick() {
      onClose();
    },
    description: "Stäng",
    ...details,
  };
}