/**
 * Contains more information about a specific feature.
 */
export class FeatureMetadata {
  readonly feature: Feature;
  readonly name: string;
  readonly description: string;

  constructor(deriveFrom?: Partial<FeatureMetadata>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}

/**
 * A feature, if enabled, hides or shows parts of the app for a more minimalistic and tailored user experience.
 */
export enum Feature {
  Contact = 'contact',
  Dial = 'dial',
  Offer = 'offer',
  Zavann = 'zavann',
  Events = 'events',
  Calendar = 'calendar',
  Project = 'project',
  RegistrationLink = 'reglink'
}

export const allFeatures = Object.values(Feature);

/**
 * Information about an enabled
 */
export class EnabledFeature {
  feature: Feature;
  since: Date = new Date();

  constructor(deriveFrom?: Partial<EnabledFeature>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);

    if (this.since) {
      this.since = new Date(this.since);
    }
  }
}

/**
 * All features which an administrator can enable or disable by their own.
 */
export const selectableFeatures: FeatureMetadata[] = [
  new FeatureMetadata({
    feature: Feature.Offer,
    name: 'Avtal',
    description: 'Skicka avtal via e-post och SMS'
  }),
  new FeatureMetadata({
    feature: Feature.Dial,
    name: 'Samtal',
    description: 'Ring samtal automatiskt'
  }),
  new FeatureMetadata({
    feature: Feature.Contact,
    name: 'Kontakter',
    description: 'Samla eller ring kontakter'
  }),
  new FeatureMetadata({
    feature: Feature.Events,
    name: "Aktivitet",
    description: "Se företagets aktivitet"
  }),
  new FeatureMetadata({
    feature: Feature.Calendar,
    name: "Schema",
    description: "Se och skapa användares schema"
  }),
  new FeatureMetadata({
    feature: Feature.Project,
    name: "Projekt",
    description: "Gruppera inställningar och dokument"
  }),
  new FeatureMetadata({
    feature: Feature.RegistrationLink,
    name: "Registreringslänkar",
    description: "Användare kan registrera sig själva via länk"
  })
];