import cloneDeep from "clone-deep";
import { validateOfferTemplate } from "../validators/offer_template";
import { createTemporaryId, isCreated } from "../utils/model";
import { OfferTemplateInsert, OfferTemplateInsertType } from "./OfferTemplateInsert";
import { Visibility } from "./Visibility";
import User from "./User";
import { OfferStatusLinks, SignedOfferLink } from "./OfferStatusLinks";
import { OfferTemplateMessageTemplateConfig } from "./OfferTemplateMessageTemplateConfig";

export class OfferTemplateOrderConfiguration {
  enabled?: boolean;
  tagIds?: string[];
}

export class OfferTemplateDefaultProduct {
  readonly productId: string;
}

/**
 * Base model for offers.
 */
export class OfferTemplate {
  readonly id: string;
  readonly title: string = 'Ny mall';
  readonly type: OfferTemplateType = null;
  readonly externalDocumentFile?: File;

  /**
   * Renderable images of each page.
   */
  readonly externalDocumentPageImages?: OfferTemplateExternalDocumentPage[];
  readonly externalDocument?: {
    file: {
      uri: string;
      type: string;
      pageMetadata?: any;
    };
    pageCount: number;
    pageMetadata: any[];
  };
  readonly cover?: {
    /**
     * May be null if `recovered` is true.
     */
    uri?: string;

    /**
     * Whether recovered by backup. If true, then the file will be unset.
     */
    recovered?: boolean;

    /**
     * File to be uploaded.
     */
    file?: File;

    width?: number;
    height?: number;
  };

  /**
   * For offer templates of type ExternalDocuments only.
   */
  readonly inserts: OfferTemplateInsert[] = [];

  /**
   * For offer templates of type Components only.
   */
  readonly components?: OfferTemplateComponent[] = [];

  /**
   * ID of a (removed) offer template which this template derives from.
   */
  readonly parentId?: string;

  /**
   * Whether this template is removed but required for
   * existing offers.
   */
  readonly isRemoved?: boolean;
  readonly isVoucherEnabled: boolean = true;

  /**
   * Default sign method.
   * 
   * Note: if an instance of Offer is available, then prioritize the offer's sign method.
   */
  readonly signMethod: OfferSignMethod = OfferSignMethod.Draw;
  readonly allowedSignMethods?: OfferSignMethod[];
  readonly statusLinks: OfferStatusLinks = new OfferStatusLinks();
  readonly orderConfiguration?: OfferTemplateOrderConfiguration;
  readonly reminderConfiguration?: {
    enabled?: boolean;
  };
  readonly messageTemplateConfiguration?: OfferTemplateMessageTemplateConfig;
  readonly visibility?: Visibility;
  readonly defaultProducts: OfferTemplateDefaultProduct[] = [];
  /**
   * Null means any project or none.
   */
  readonly projectIds?: string[] = null;

  constructor(deriveFrom?: Partial<OfferTemplate>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);

    if (this.components) {
      this.components = this.components.map(cmp => new OfferTemplateComponent(cmp))
    }

    if (this.inserts) {
      this.inserts = this.inserts.map(insert => new OfferTemplateInsert(insert));
    }

    if (this.visibility) {
      this.visibility = new Visibility(this.visibility);
    }

    if (!this.statusLinks) {
      this.statusLinks = new OfferStatusLinks({
        signed: new SignedOfferLink({
          message: this.signMethod === OfferSignMethod.OneClick ? "Dokumentet har godkänts." : "Dokumentet har signerats."
        })
      });
    } else {
      this.statusLinks = new OfferStatusLinks(this.statusLinks);
    }
  }

  /**
   * @returns Whether the user has changed the offer's external document, without saving.
   */
  get hasExternalDocumentChanged(): boolean | undefined {
    if (this.type !== OfferTemplateType.ExternalDocument) {
      return undefined;
    }

    return !!this.externalDocumentFile || (this.externalDocument?.file.uri && !this.externalDocument.file.uri.startsWith('http'));
  }

  get signatureLocationCount() {
    if (this.type === OfferTemplateType.Components) {
      return this.hasComponentByType(OfferTemplateComponentType.Reply) ? 1 : 0;
    }

    return this.inserts.filter(insert => insert.type === OfferTemplateInsertType.Signature).length;
  }

  getComponentByType(type: OfferTemplateComponentType) {
    return this.components?.find(cmp => cmp.type == type);
  }

  hasComponentByType(type: OfferTemplateComponentType) {
    return this.getComponentByType(type) != null;
  }

  validate() {
    return validateOfferTemplate(this);
  }

  /**
   * @returns Optimized payload which passes server validation. 
   */
  toPayload() {
    if (!this.components?.length) {
      return this;
    }

    const payload: OfferTemplate = cloneDeep(this, true);

    for (let cmp of payload.components) {
      if (!isCreated(cmp.id)) {
        cmp.id = null;
      }

      if (cmp.type === OfferTemplateComponentType.Multi) {
        const args = cmp.arguments.multi;
        for (let group of args.groups) {
          const isExternalImage = /^http/i.test(group.image?.uri);

          if (group.image && !isExternalImage) {
            delete group.image.uri;
          }
        }
      } else if (cmp.type === OfferTemplateComponentType.Text) {
        const args = cmp.arguments?.text;
        const isExternalImage = /^http/i.test(args.image?.uri);

        if (args.image && !isExternalImage) {
          delete cmp.arguments?.text.image.uri;
        }
      }
    }

    return payload;
  }

  get isCreated() {
    return isCreated(this.id);
  }

  isVisible(currentUser: User) {
    return !this.visibility || this.visibility.isVisible(currentUser);
  }
}

export enum OfferSignMethod {
  Draw = 'draw',
  OneClick = 'oneClick',
  BankID = 'bankId'
}

export class OfferTemplateExternalDocumentPage {
  uri: string;
  type: string;
  width: number;
  height: number;
}

export class OfferTemplateComponent {
  id: string = createTemporaryId();
  type: OfferTemplateComponentType;
  arguments: {
    text?: OfferTemplateTextComponentArguments;
    multi?: OfferTemplateMultiComponentArguments;
    price?: OfferTemplatePriceComponentArguments
  } = {};

  /**
   * Assists delete animation.
   * Only set when deleting in editor.
   */
  deleted?: boolean;

  constructor(deriveFrom?: Partial<OfferTemplateComponent>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);

    switch (this.type) {
      case OfferTemplateComponentType.Text:
        this.arguments = this.arguments || { text: { text: null, image: null } as OfferTemplateTextComponentArguments };
        break;

      case OfferTemplateComponentType.Price:
        this.arguments = this.arguments || {
          price: {
            billingType: OfferTemplatePriceComponentBillingType.Fixed,
            type: OfferTemplatePriceComponentType.Single
          } as OfferTemplatePriceComponentArguments
        };
        break;
    }
  }
}
export enum OfferTemplateType {
  /**
   * The offer template has an external document basis,
   * such as a PDF, and <em>inserts</em> which is specific for
   * the recipient. Examples of inserts includes information inserts and
   * signature inserts.
   */
  ExternalDocument = 'externalDocument',

  /**
   * The offer template is built by the user using in-app
   * building blocks called <em>components</em>.
   */
  Components = 'components'
}

export enum OfferTemplateComponentType {
  Text = 'text',
  Price = 'price',
  Multi = 'multi',
  Reply = 'reply'
}

export type OfferTemplateTextComponentImagePosition = 'top' | 'left' | 'bottom' | 'right';

export type OfferTemplateTextComponentImageArguments = {
  uri: string;
  position: OfferTemplateTextComponentImagePosition;

  /**
   * Whether recovered by backup. If true, then the file will be unset.
   */
  recovered?: boolean;

  /**
   * File which was selected as the image.
   * Applicable if the image file has not been uploaded yet.
   */
  file?: File;

  /**
   * Name of the original file, used for recovery.
   */
  fileName?: string;

  width?: number;
  height?: number;
}

export type OfferTemplateTextComponentArguments = {
  header?: string;
  text: string;
  image?: OfferTemplateTextComponentImageArguments;
}

export type OfferTemplateMultiComponentGroup = {
  id?: string;
  name: string;

  /**
   * Whether values have changed.
   * Set by the editor, otherwise undefined.
   */
  changed?: boolean;

  /**
   * Whether to inherit the header as the name.
   * Set by the editor.
   */
  inheritName?: boolean;
} & OfferTemplateTextComponentArguments;

export type OfferTemplateMultiComponentArguments = {
  groups: OfferTemplateMultiComponentGroup[];
}

export enum OfferTemplatePriceComponentType {
  Single = 'single',
  Table = 'table'
}

export enum OfferTemplatePriceComponentBillingType {
  Fixed = 'fixed',
  Variable = 'variable'
}

export type OfferTemplatePriceComponentGroup = {
  name: string;
} & OfferTemplateTextComponentArguments;

export type OfferTemplatePriceSingle = {
  sum: number;
  vat: number;
}

export type OfferTemplatePriceComponentArguments = {
  type: OfferTemplatePriceComponentType;
  billingType: OfferTemplatePriceComponentBillingType;
};

export type OfferTemplateComponentArguments = OfferTemplateTextComponentArguments
  | OfferTemplateMultiComponentArguments
  | OfferTemplatePriceComponentArguments;