import React from 'react';
import ActionNotification from '../ActionNotification/ActionNotification';

type Props = {
  onFilesOpen: (files: File[]) => void;
  acceptPreset?: 'images';

  /**
   * Whether to allow selecting multiple files.
   * @default false
   */
  multiple?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

/**
 * Renders a hidden file input.
 * @author Johan Svensson
 */
export default function FileHandler(props: Props) {
  const multiple = props.multiple ?? false;
  let accept = props.accept;

  switch (props.acceptPreset) {
    case 'images':
      accept = 'image/png,image/jpg,image/jpeg,image/gif,image/webp';
      break;
  }

  props = { ...props, acceptPreset: undefined };

  return (
    <input
      {...props}
      accept={accept}
      onChange={e => {
        const { files } = e.currentTarget
        if (!(files || []).length) {
          return;
        }

        for (let file of files) {
          if (!validateFileSize(file)) {
            return;
          }
        }

        props.onFilesOpen([...files]);
      }}
      type="file"
      className="hidden"
      multiple={multiple} />
  )
}

const megabyte = Math.pow(1024, 2);
export const maxFileSizeInBytes = 50 * megabyte;

/**
 * Validates the file size of a given `file`.
 * Shows a notification if the file size exceeds `maxFileSizeInBytes`.
 * @returns Whether the file size is valid.
 */
export function validateFileSize(file: File): boolean {
  if (file.size > maxFileSizeInBytes) {
    ActionNotification.show('Filen är för stor. Max 50 MB');
    return false;
  }

  return true;
}