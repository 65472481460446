import Offer from "../../models/Offer";
import { OfferTemplate, OfferTemplateType } from "../../models/OfferTemplate";
import Cookies from 'js-cookie';
import { apis } from "../../backend";
import OfferEvent from "../../models/OfferEvent";
import { OfferStatusLinks } from "../../models/OfferStatusLinks";
import { Country } from "../../models/Country";

//  See s2-offers.
const externalDocumentTypeIdentifiers = ['DO', 'ED', 'EX'];
const componentTypeIdentifiers = ['DO', 'CM', 'MP'];
const versionIdentifiers = [[], ['C', 'G', 'O', 'F', 'A']];

function decodePublicOfferIdV2(publicOfferId: string): { templateType: OfferTemplateType; country: Country; } {
  //  Remove version.
  const templateTypeIdentifier = publicOfferId.substring(1, 3);
  const countryUpperCase = publicOfferId.substring(3, 5);

  let templateType: OfferTemplateType = null;
  if (externalDocumentTypeIdentifiers.includes(templateTypeIdentifier)) {
    templateType = OfferTemplateType.ExternalDocument;
  } else if (componentTypeIdentifiers.includes(templateTypeIdentifier)) {
    templateType = OfferTemplateType.Components;
  }

  return {
    templateType,
    country: countryUpperCase.toLowerCase() as Country
  }
}

function decodePublicOfferIdV1(publicOfferId: string): { templateType: OfferTemplateType; } {
  let templateType: OfferTemplateType = null;
  if (externalDocumentTypeIdentifiers.find(identifier => publicOfferId.startsWith(identifier))) {
    templateType = OfferTemplateType.ExternalDocument;
  } else if (componentTypeIdentifiers.find(identifier => publicOfferId.startsWith(identifier))) {
    templateType = OfferTemplateType.Components;
  }

  return {
    templateType
  }
}

export function decodePublicOfferId(publicOfferId: string): { templateType: OfferTemplateType; country?: Country; } | null {
  if (typeof publicOfferId !== 'string' || publicOfferId.length === 10) {
    //  Old, and not encoded.
    return null;
  }

  if (publicOfferId.length === 9) {
    //  Version 1 has 9 characters.
    return decodePublicOfferIdV1(publicOfferId);
  }

  const version = versionIdentifiers.findIndex(identifiers =>
    identifiers.find(identifier => publicOfferId.startsWith(identifier))) + 1;

  console.log("PublicId version: %d", version);

  if (!version) {
    console.warn("Uknown publicId version: %s", publicOfferId);
    return null;
  }

  if (version === 2) {
    return decodePublicOfferIdV2(publicOfferId);
  }

  return null;
}

/**
 * Fetches the offer by its public ID.
 * @param offerPublicId The offer's public ID.
 * @param information Set of properties to limit the response to, for optimization.
 */
export async function getPublicOffer(offerPublicId: string): Promise<{ offer: Offer; template: OfferTemplate }> {
  const url = new URL(document.location.href);

  const { offer, template } = await apis.offers.get(`/offers-public-v2/${offerPublicId}`, {
    read: !window.location.search.includes('read=no'),
    printToken: url.searchParams.get('printToken') // Used by document service to create print.
  });
  return { offer: new Offer(offer), template: new OfferTemplate(template) };
}

/**
 * Requests a signature token.
 * The token is saved to the user's cookies and not returned.
 */
export async function requestSignatureToken(offerId: string) {
  Cookies.remove('s2_signatureToken');
  await apis.offers.post(`/offer-responses-v1/${offerId}/signature-token`);
}

/**
 * Requests a decline token.
 * The token is saved to the user's cookies and not returned.
 */
export async function requestDeclineToken(offerId: string) {
  Cookies.remove('s2_declineToken');
  await apis.offers.post(`/offer-responses-v1/${offerId}/decline-token`);
}

/**
 * Use this for getting a session ID.
 * If the session ID is set, use `createOfferEventAsync`!
 */
export async function createOfferEvent(offerId: string, event: OfferEvent): Promise<{ eventId: string; sessionId?: string; }> {
  if (isPrint()) {
    return { eventId: null, sessionId: null };
  }

  return apis.offers.post(`/offer-events-v2/${offerId}`, event);
}

export function createOfferEventAsync(offerId: string, event: OfferEvent) {
  if (navigator.sendBeacon) {
    navigator.sendBeacon(apis.offers.getUrl(
      `/offer-events-v2/${offerId}`),
      new Blob([JSON.stringify(event)], { type: 'application/json' })
    );
  } else {
    createOfferEvent(offerId, event);
  }
}

const { searchParams } = new URL(document.location.href);
let _print: boolean = !!(searchParams.get("print") || searchParams.get("printToken"));

/**
 * @returns Whether the print version of the offer is requested.
 */
export function isPrint() {
  return _print;
}

export async function getOfferStatusLinks(offerId: string): Promise<OfferStatusLinks | null> {
  const result = await apis.offers
    .get<{ statusLinks: OfferStatusLinks; offerId: string }[]>(
      '/offer-information-v1/status-links', { offerId });

  const statusLinks = result.find(result => result.offerId === offerId)?.statusLinks;
  if (statusLinks) {
    return new OfferStatusLinks(statusLinks);
  }

  return null;
}